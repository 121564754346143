import { useState } from 'react';
import { Button, Dialog, Heading, IconButton, Modal, ModalOverlay } from '@launchpad-ui/components';

import { DestructiveButton } from 'components/ui/buttons';

export type NavigationPromptModalProps = {
  onCancelNavigation(): void;
  onConfirmNavigation(): void;
  title?: string;
  body?: string;
  cancelText?: string;
  confirmText?: string;
};

/* eslint-disable import/no-default-export */
export default function NavigationPromptModal({
  onCancelNavigation,
  onConfirmNavigation,
  title = 'You have unsaved changes',
  body = 'If you leave this page, you will lose any unsaved changes',
  cancelText = 'Return to editing',
  confirmText = 'Leave page',
}: NavigationPromptModalProps) {
  const [open, setOpen] = useState(true);
  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    !isOpen && onCancelNavigation();
  };

  return (
    <ModalOverlay isOpen={open} onOpenChange={handleOpenChange}>
      <Modal size="small">
        <Dialog>
          <div slot="header">
            <Heading slot="title">{title}</Heading>
            <IconButton aria-label="close" icon="cancel" size="small" variant="minimal" slot="close" />
          </div>
          <div slot="body">
            <p>{body}</p>
          </div>
          <div slot="footer">
            <Button onPress={onCancelNavigation}>{cancelText}</Button>
            <DestructiveButton onPress={onConfirmNavigation}>{confirmText}</DestructiveButton>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
